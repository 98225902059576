import React from 'react';
import { CSSProperties } from 'react';
import { ClickableCard } from 'styleguide/ClickableCard';
import { TextSMMedium } from 'styleguide/Texts';
import GoogleLoginImage from 'assets/google-login.svg';
import AppleLoginImage from 'assets/apple-login.svg';
import { View } from 'styleguide/View';
import styled from 'styled-components';
import { Spacing } from 'styleguide/spacing';

type SocialButtonType = 'google' | 'apple';
type SocialButtonAction = 'login' | 'signup';

interface SocialButtonProps {
  type: SocialButtonType;
  action: SocialButtonAction;
  onClick: () => void;
  style?: CSSProperties;
}

const ImageContainer = styled.div`
  height: 30px;
  width: 30px;
  margin-right: ${Spacing.sm}px;
`;

export const SocialAuthenticationButton = ({ type, action, onClick, style }: SocialButtonProps) => {
  const ImageSVG = image(type);

  return (
    <ClickableCard style={style} onClick={onClick}>
      <View
        style={{
          flexDirection: 'row',
          height: '35px',
          justifyContent: 'center',
        }}
      >
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <ImageContainer>
            <ImageSVG />
          </ImageContainer>

          <TextSMMedium>{title(type, action)}</TextSMMedium>
        </View>
      </View>
    </ClickableCard>
  );
};

const image = (type: SocialButtonType) => {
  switch (type) {
    case 'google':
      return GoogleLoginImage;
    case 'apple':
      return AppleLoginImage;
  }
};

const title = (type: SocialButtonType, action: SocialButtonAction) => {
  if (action === 'login') {
    switch (type) {
      case 'google':
        return 'Continue with Google';
      case 'apple':
        return 'Continue with Apple';
    }
  } else if (action === 'signup') {
    switch (type) {
      case 'google':
        return 'Sign up with Google';
      case 'apple':
        return 'Sign in with Apple';
    }
  }
};

interface LoginWithGoogleButtonProps {
  action: SocialButtonAction;
  onClick: () => void;
  style?: CSSProperties;
}

export const LoginWithGoogleButton = ({ action, style, onClick }: LoginWithGoogleButtonProps) => {
  return (
    <SocialAuthenticationButton action={action} style={style} type="google" onClick={onClick} />
  );
};
