import { IReactionDisposer, makeAutoObservable } from 'mobx';
import { CoachSocialLoginResult, isValidEmail, isValidUsername } from 'hevy-shared';
import { localStorageStores } from 'state/localStorageStores';
import { sendEvent } from 'utils/analyticsEvents';
import axios from 'axios';
import { captureException } from '@sentry/nextjs';
import {
  authorizeWithApple,
  handleAuthenticationComplete,
  requestPasswordRecovery,
} from 'screens/Authentication/authenticationUtils';
import { CodeResponse } from '@react-oauth/google';

type ScreenState = 'login' | 'forgot-password' | 'loading';

export class LoginViewModel {
  usernameOrEmail: string = '';
  password: string = '';
  errorMessage?: string;
  screenState: ScreenState = 'loading';
  isLoading: boolean = false;
  usernameOrEmailErrorStatus?: string;
  passwordErrorStatus?: string;
  bootstrapDisposer?: IReactionDisposer;
  temporaryAuthToken?: string;

  constructor(temporaryAuthToken?: string) {
    makeAutoObservable(this);

    sendEvent('login_screenview');
    this.temporaryAuthToken = temporaryAuthToken;
    if (!!temporaryAuthToken) {
      this.screenState = 'loading';
      this.loginFromTempToken();
    } else {
      this.screenState = 'login';
    }
  }

  loginFromTempToken = async () => {
    if (!this.temporaryAuthToken) return;
    try {
      const result = await localStorageStores.auth.loginWithTempToken(this.temporaryAuthToken);
      await handleAuthenticationComplete({
        didJoinTeam: false,
        is_first_login_to_coach_platform: result.is_first_login_to_coach_platform,
      });
    } catch (error) {
      captureException(error);
      this.screenState = 'login';
    }
  };

  get isValidEmailorUsername() {
    return isValidEmail(this.usernameOrEmail) || isValidUsername(this.usernameOrEmail);
  }

  get isValidPassword() {
    return this.password.length > 5;
  }

  setScreenState = (screenState: ScreenState) => {
    this.screenState = screenState;
  };

  /**
   * set error messages and highlight fields with invalid input
   * @param errorMessage error message to display in red text at the bottom of the form
   * @param usernameOrEmailErrorStatus error message to display next to the email/username field
   * @param passwordErrorStatus error message to display next to the password field
   */
  setError = (
    errorMessage: string | undefined,
    usernameOrEmailErrorStatus?: string,
    passwordErrorStatus?: string,
  ) => {
    this.errorMessage = errorMessage;
    this.usernameOrEmailErrorStatus = usernameOrEmailErrorStatus;
    this.passwordErrorStatus = passwordErrorStatus;
  };

  clearError = () => {
    this.setError(undefined);
  };

  /**
   * Google login
   */

  onLoginWithGoogleClick = async (response: CodeResponse) => {
    this.isLoading = true;

    sendEvent('login_click', { source: 'google' });

    try {
      const loginResult = await localStorageStores.auth.loginWithGoogle(response.code);
      sendEvent('login_complete', {
        source: 'google',
        coachSignUp: loginResult.is_first_login_to_coach_platform,
      });
      await handleAuthenticationComplete({
        hasPickedAUsername: loginResult.is_new_user !== true,
        didJoinTeam: false,
        ...loginResult,
      });
    } catch {
      this.onGoogleLoginError();
      this.isLoading = false;
      return;
    }
  };

  onGoogleLoginError = () => {
    this.errorMessage = 'Failed to login with Google.';
  };

  /**
   * Apple Login
   */

  onLoginWithAppleClick = async () => {
    sendEvent('login_click', { source: 'apple' });
    this.errorMessage = undefined;

    let loginResult: CoachSocialLoginResult;

    this.isLoading = true;
    try {
      loginResult = await authorizeWithApple();
      sendEvent('login_complete', {
        source: 'apple',
        coachSignUp: loginResult.is_first_login_to_coach_platform,
      });
      await handleAuthenticationComplete({
        hasPickedAUsername: loginResult.is_new_user !== true,
        didJoinTeam: false,
        ...loginResult,
      });
    } catch (e) {
      this.isLoading = false;
      if ((e as any)?.error === 'popup_closed_by_user') return;

      this.errorMessage = 'Failed to login with Apple.';
      return;
    }
  };

  /**
   * Email Login
   */

  onUpdateUsernameOrEmail = (value: string) => {
    this.usernameOrEmail = value;
    this.usernameOrEmailErrorStatus = undefined;
  };

  onUpdatePassword = (value: string) => {
    this.password = value;
    this.passwordErrorStatus = undefined;
  };

  onLoginPress = async () => {
    if (!this.isValidPassword || !this.isValidEmailorUsername) {
      this.setError(
        undefined,
        !this.isValidEmailorUsername
          ? 'Username must contain between 3 and 20 characters'
          : undefined,
        !this.isValidPassword ? 'Password must contain at least 6 characters' : undefined,
      );
      return;
    }

    sendEvent('login_click', { source: 'email' });
    this.clearError();

    try {
      this.isLoading = true;
      const result = await localStorageStores.auth.login(this.usernameOrEmail, this.password);
      sendEvent('login_complete', {
        source: 'email',
        coachSignUp: result.is_first_login_to_coach_platform,
      });
      handleAuthenticationComplete({ ...result, didJoinTeam: false });
    } catch (e: unknown | import('axios').AxiosError) {
      this.isLoading = false;

      if (axios.isAxiosError(e)) {
        switch (e.response?.data.error) {
          case 'InvalidEmailOrPassword':
            this.setError('Invalid username, email or password');
            break;
          default:
            this.setError('An unknown error has occurred');
            sendEvent('login_error', { source: 'email', error: e.message });
        }

        return;
      }

      this.setError('An unknown error has occurred');
    }
  };

  passwordRecoveryEmail: string = '';
  isPasswordRecoveryEmailLoading: boolean = false;
  onUpdatePasswordRecoveryEmail = (value: string) => {
    this.passwordRecoveryEmail = value;
  };

  get isSendPasswordRecoveryEnabled() {
    return isValidEmail(this.passwordRecoveryEmail);
  }

  onSendPasswordRecovery = async () => {
    this.isPasswordRecoveryEmailLoading = true;
    try {
      await requestPasswordRecovery(this.passwordRecoveryEmail);
      this.setScreenState('login');
    } catch {
    } finally {
      this.isPasswordRecoveryEmailLoading = false;
    }
  };
}
