import {
  LoginWithGoogleButton,
  SocialAuthenticationButton,
} from 'components/SocialAuthenticationButton';
import React from 'react';
import { PrimaryButton } from 'styleguide/Buttons';
import { colors } from 'styleguide/colors';
import { DividerLine } from 'styleguide/DividerLine';
import { LabelledFormInput } from 'styleguide/Inputs';
import { Spacing } from 'styleguide/spacing';
import { DisplayXS, TextSMMedium, TextSMRegular } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import Link from 'next/link';
import { useDeviceSize } from 'utils/useSize';
import { sendEvent } from 'utils/analyticsEvents';
import router from 'next/router';
import { FlexRow } from 'styleguide/Row';

interface LoginCardProps {
  usernameOrEmail: string;
  onUpdateUsernameOrEmail: (value: string) => void;
  usernameOrEmailErrorStatus?: string;
  password: string;
  onUpdatePassword: (value: string) => void;
  passwordErrorStatus?: string;
  isLoading: boolean;
  onLogin: () => void;
  loginErrorMessage?: string;
  onForgotPassword: () => void;
  onAppleLogin: () => void;
  onGoogleLogin: () => void;
}

export const LoginCard = ({
  usernameOrEmail,
  onUpdateUsernameOrEmail,
  usernameOrEmailErrorStatus,
  password,
  onUpdatePassword,
  passwordErrorStatus,
  isLoading,
  onLogin,
  loginErrorMessage,
  onAppleLogin,
  onGoogleLogin,
  onForgotPassword,
}: LoginCardProps) => {
  const deviceSize = useDeviceSize();
  const headerTextAlign = deviceSize === 'desktop' ? 'center' : 'left';
  return (
    <View style={{ width: '100%', maxWidth: 400 }}>
      <View style={{ flex: 1 }}>
        <DisplayXS style={{ textAlign: headerTextAlign }}>Welcome back!</DisplayXS>
        <TextSMRegular
          style={{
            textAlign: headerTextAlign,
            marginBottom: deviceSize === 'desktop' ? Spacing.lg * 2 : Spacing.lg,
            marginTop: Spacing.sm,
          }}
        >
          Let's get you signed in
        </TextSMRegular>
        <SocialAuthenticationButton
          action="login"
          style={{ marginBottom: Spacing.md }}
          type="apple"
          onClick={onAppleLogin}
        />
        <LoginWithGoogleButton
          action="login"
          style={{ marginBottom: Spacing.md }}
          onClick={onGoogleLogin}
        />
        <FlexRow style={{ marginTop: Spacing.sm, marginBottom: Spacing.sm }}>
          <DividerLine />
          <TextSMMedium style={{ color: colors.neutral500, width: '100%', textAlign: 'center' }}>
            or with email
          </TextSMMedium>
          <DividerLine />
        </FlexRow>
        <form onSubmit={e => e.preventDefault()}>
          <LabelledFormInput
            autoFocus
            tabIndex={3}
            label="Email or username"
            spellCheck={false}
            containerStyle={{ marginBottom: Spacing.md }}
            value={usernameOrEmail}
            autoCapitalize="none"
            onChange={event => onUpdateUsernameOrEmail(event.currentTarget.value)}
            errorMessage={usernameOrEmailErrorStatus}
          />
          <LabelledFormInput
            tabIndex={4}
            secondaryActionLabel="Forgot your password?"
            secondaryActionPressed={onForgotPassword}
            label="Password"
            type={'password'}
            containerStyle={{ marginBottom: Spacing.md }}
            value={password}
            onChange={event => onUpdatePassword(event.currentTarget.value)}
            errorMessage={passwordErrorStatus}
          />
          <PrimaryButton
            tabIndex={5}
            type={'submit'}
            title={'Login'}
            style={{ width: '100%', marginBottom: Spacing.lg }}
            loading={isLoading}
            onClick={onLogin}
          />
        </form>
        {!!loginErrorMessage && (
          <View>
            <TextSMMedium style={{ color: colors.error500, marginBottom: Spacing.md }}>
              {loginErrorMessage}
            </TextSMMedium>
          </View>
        )}
        <TextSMRegular style={{ color: colors.neutral900 }}>
          Don't have an account?{' '}
          <Link
            href="#"
            onClick={() => {
              sendEvent('loginScreen_signup_click');
              const query = router.query;
              router.push({ pathname: '/', query });
            }}
          >
            Sign up
          </Link>
        </TextSMRegular>
      </View>
    </View>
  );
};
